let QuickViewPopover
function getQuickView () {
  if (typeof window !== 'undefined' && QuickViewPopover) { 
    return Promise.resolve(QuickViewPopover)
  }
  if (QuickViewPopover) {
    return Promise.resolve(QuickViewPopover)
  }
  return import(/* webpackChunkName: "product-intro-quick-view" */ 'public/src/pages/common/quickView').then(res => {
    QuickViewPopover = res.default
    return QuickViewPopover
  })
}


export default getQuickView
