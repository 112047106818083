// 合规场景特殊处理
export const extendsComplianceModeConfig = (config) => {
  config.standardPrice = true
  // config.hideFlashSaleIcon = true
  // config.hideIconBeforePrice = true
  // config.showSheinClubPriceOnSale = false
  // config.showPromotion = false
  // config.showDiscountLabelAfterPrice = false
  // config.showSheinClubDiscountValue = false
  // config.showSheinClubDiscountValueAfterPrice = false
  // config.showNewDiscountLabel = false
  // config.style = { ...config.style, salePriceColor: '#000' }
}
