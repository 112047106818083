<script>
export default {
  name: 'AttrPicInfo'
}
</script>
<script setup>
import { transformImg } from '@shein/common-function'

defineProps({
  text: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: ''
  },
})
</script>

<template>
  <div
    v-if="image"
    class="product-intro__attr-picinfo"
  >
    <div
      v-if="text"
      class="product-intro__attr-picinfo-text"
    >
      {{ text }}
    </div>
    <img
      class="product-intro__attr-picinfo-image"
      :src="transformImg({ img: image })"
    />
  </div>
</template>

<style lang="less" scoped>

.product-intro__attr-picinfo {
  &-text {
    font-size: 14px;
    font-weight: 700;
    color: @sui_color_brand;
  }

  &-image {
    margin-top: 8px;
    height: 72px;
  }
}
</style>
