export default {
  'detail_title': 1,
  'detail_pop': 3,
  'cart_title': 2,
  'cart_pop': 4,
  'detail_section': 5,
  'detail_tips': 7,
  'cart_tips': 8,
  'pc_size_fit': 6,
}
